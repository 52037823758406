@import "general";

:root {
    --container-n-elements: 4;
}

@mixin background-pattern($position: 98%) {
    background-color: #fff;
    background-image: url(/assets/img/design_pattern.svg);
    background-position: $position;
    background-repeat: repeat-y;
    background-size: 5vmax;
}

#whats-madrigal-card {
    @include background-pattern(98%);

    #whats-madrigal {
        position: relative;
        white-space: pre-wrap;
    }
}

.container-card.no-icon {
    @include background-pattern(95%);
}

.container-card.card-static {
    pointer-events: none;
    cursor: default;
}

.container-card.card-static::before {
    display: none;
}

.container-card {
    position: relative;
}

.container-card::before {
    content: '';
    position: absolute;
    left: -1px;
    top: 19.1%;
    height: 61.8%;
    width: 5px;
    border-radius: 4px;

    background-color: $primary;
    transition: background-color 0.2s ease-in-out;
}

.container-card:hover::before {
    background-color: $secondary;
}

#main-block {
    height: calc(100dvh - ($menu-logo-width / 1911 * 174) - ($spacer * 2));

    @include media-breakpoint-down(lg) {
        height: 400px !important;
    }

    #main-block-left {
        background-color: $burgundy;
        min-height: 100%;
        width: 61.8%; // Золотое сечение!!!

        @include media-breakpoint-down(lg) {
            width: 100%;
            height: auto;
        }

        position: relative;

        #main-block-ring-dark {
            position: absolute;
            height: 100%;
            left: -50%;
            max-width: 150vw;
        }

        #madrigal-header-container {
            position: relative;
            color: white;
        }
    }

    #main-block-right {
        min-height: 100%;
        width: 38.2%;

        @include media-breakpoint-down(lg) {
            display: none !important;
        }

        img {
            width: 700px;
        }
    }
}


.container-card {
    text-decoration: none;
    display: block;
    min-height: 10em;

    @include media-breakpoint-down(md) {
        min-width: auto;
    }

    min-width: calc((100% - ($spacer * (var(--container-n-elements) - 1))) / var(--container-n-elements)); // 100% - N-1 gaps divided to N

    scroll-snap-align: start;
}

.cards-container {
    overflow-x: hidden;

    -ms-overflow-style: none;
    scrollbar-width: none;

    position: relative;

    scroll-snap-type: x mandatory;

    margin-top: -1rem;
    padding-top: 1.75rem;

    .card {
        transition: transform 0.2s, border-color 0.2s;
    }
  
    .card:hover {
        transform: translateY(-10px);
        border-color: $secondary;
    }
}

.cards-container::-webkit-scrollbar {
    display: none;
}

.card-icon {
    max-height: 100px;
}

.card-container-control {
    @include media-breakpoint-down(md) {
        display: none;
    }
}

.card-container-control-prev {
    margin-left: -2.4em;
}

.card-container-control-next {
    margin-right: -2.4em;
}

#news-block {
    margin-top: .75rem;    
    margin-left: -0.6em;
    margin-right: -0.6em;

    @include media-breakpoint-down(md) {
        margin-left: 0;
        margin-right: 0;
    }
}
