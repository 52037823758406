$primary: #73212C;
$secondary: #F08526;
$black: #1A1A18;
$burgundy: #82333E;

$dark: #2B2A29;
$font-size-base: 1.1rem;

$card-border-radius: 1.125rem;
// $input-btn-border-radius: 10rem;

$enable-grid-classes: true;
$enable-cssgrid: true;

$accordion-button-active-bg: $primary;
$accordion-button-active-color: white;
$accordion-icon-active-color: white;

@import "bootstrap/scss/bootstrap";

$bootstrap-icons-font-dir: "/node_modules/bootstrap-icons/font/fonts";
@import "bootstrap-icons/font/bootstrap-icons";

$menu-logo-width: 15em;
$menu-logo-width-sm: 12em;

* {
    font-family: "Ubuntu";
}

#menu #logo {
    width: fit-content;
    max-height: 48px;
    margin-bottom: -0.5rem;
    margin-top: -0.5rem;
    max-width: 70vw;
}

.btn-link {
    text-decoration: none;

    span {
        text-decoration: underline;
    }
}

footer>div>div {
    flex: 1;
}

.top-compliant {
    height: calc(100dvh - ($menu-logo-width / 1911 * 174) - ($spacer * 3.5) - 1.125rem - (1.1rem * 1.5));
}

h6 {
    font-weight: 300;
    font-size: 1.2rem;
}

h1 {
    font-size: 2.5rem;
    font-weight: 500;
}

h4 {
    font-weight: 400;
    font-size: 1.4rem;
}

.btn-secondary {
    color: white;
}

/* Цвет скроллбара */
::-webkit-scrollbar {
    width: 8px;
    /* ширина скроллбара */
}

/* Трек */
::-webkit-scrollbar-track {
    background: #f1f1f1;
    /* цвет трека */
}

/* Линия на скроллбаре */
::-webkit-scrollbar-thumb {
    background: #888;
    /* цвет линии на скроллбаре */
}

/* Линия на скроллбаре при наведении */
::-webkit-scrollbar-thumb:hover {
    background: #555;
    /* цвет линии на скроллбаре при наведении */
}

.btn-shadow {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

.bg-ring {
    background-image: url(/assets/img/ring_dark.svg);
    background-position: calc(100% - 20vw) calc(50% - 20vh);
    background-repeat: no-repeat;
    background-size: 100%;
}

.display-7 {
    --font-size: 1.75rem;
    font-size: var(--font-size);
}

.bg-burgundy {
    background-color: $burgundy;
}

.bb-burgundy {
    border-bottom: solid tint-color($primary, 25%) 2px;
}

.accordion-body {
    word-wrap: break-word;
}
